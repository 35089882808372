@import "/src/ui/style/config";

@range-width: 200px;

.checkbox(@radius: 0) {
	color: @color-dark-face-txt;
	position: relative;
	box-sizing: border-box;
	min-height: @size-option;
	line-height: @size-option;
	padding-left: (@size-option + @indent-small);

	input {
		vertical-align: middle;
		margin-left: 0;
		position: absolute;
		opacity: 0;
		pointer-events: none;
	}

	span {
		&::before {
			content: "";
			position: absolute;
			display: block;
			opacity: 0;
			height: (@size-option / 2);
			width: (@size-option / 2);
			background-color: @color-main-txt;
			left: (@size-option / 4);
			top: (@size-option / 4);
			border-radius: 50%;
			z-index: 2;
		}

		&::after {
			content: "";
			position: absolute;
			display: block;
			height: @size-option;
			width: @size-option;
			box-sizing: border-box;
			background-color: @color-main-bg;
			border: 1px solid @color-dark-face-brd;
			color: @color-main-bg;
			border-radius: @radius;
			left: 0;
			top: 0;
			z-index: 1;

			.form__group_error & {
				border: 1px solid @color-danger-bg;
			}

			.form__group_indicate & {
				border: 1px solid @color-indicator-brd;
			}
		}
	}

	input:checked + span::before {
		opacity: 1;
	}

	input:checked + span::after {
		background-color: @color-indicator-bg;
		border: 1px solid @color-indicator-brd;

		.form__group_error & {
			border: 1px solid @color-danger-bg;
		}

		.form__group_indicate & {
			border: 1px solid @color-indicator-brd;
		}
	}
}

.form {
	margin: 0;
	overflow: hidden;

	&__section {
		border-bottom: 1px solid @color-dark-face-brd;
		flex: 1;
		padding: @indent-small @indent-large;
		color: @color-dark-face-txt-muted;
		margin: 0 -@indent-large;
	}

	&__error {
		display: none;
		padding: @indent-large;
		background-color: @color-danger-bg;
		color: @color-danger-txt;
		text-align: center;

		&_show {
			display: block;
		}
	}

	&__note {
		display: none;
		padding: @indent-large;
		background-color: @color-indicator-bg;
		color: @color-indicator-txt;
		text-align: center;

		&_show {
			display: block;
		}
	}

	&__message {
		padding: @indent-large;
		background-color: @color-face2-bg;
		text-align: center;

		&:empty {
			display: none;
		}

		a {
			color: @color-link;
			text-decoration: underline;

			&:hover {
				color: @color-link-hover;
			}
		}
	}

	&__group {
		display: flex;
		align-items: center;
		padding: @indent-large;

		&[disabled] {
			pointer-events: none;
			opacity: 0.6;
		}

		.form:not(.form_inline) .form__error + & {
			margin-top: @indent-large;
		}

		.form:not(.form_inline) &:last-of-type {
			margin-bottom: @indent-large;
		}

		.form_no-margin & {
			margin: 0 !important;
		}

		& + & {
			padding-top: 0;
		}

		&_error {

		}

		&_indicate {

		}
	}

	&__caption {
		display: block;
		width: 30%;
		max-width: 30%;
		text-align: right;
		color: @color-dark-face-txt;
		padding-right: @indent-small;

		.form_width-50 & {
			width: 50%;
			max-width: 50%;
		}

		.form_no-captions & {
			display: none;
		}

		&_for-options {
			align-self: start;
			min-height: @size-option;
			line-height: @size-option;
		}

		.form_inline & {
			width: auto;
			max-width: none;
		}

		&:not(:empty) {
			&::after {
				content: ":";
			}
		}
	}

	&__hint {
		margin-left: 30%;
		padding-left: @indent-large;
		color: @color-dark-face-txt-muted;
		margin-bottom: @indent-large;

		.form_width-50 & {
			margin-left: 50%;
		}
	}

	&__options {
		display: block;
		width: 70%;

		.form_width-50 & {
			width: 50%;
		}
	}

	&__option {
		display: block;
		width: fit-content;
		margin-bottom: @indent-small;

		.checkbox(50%);
	}

	&__label {
		display: block;
		width: 70%;

		.form_width-50 & {
			width: 50%;
		}

		.form_inline & {
			width: auto;
			max-width: none;
		}

		.checkbox();
	}

	&__range-scale {
		width: @range-width - @thumb-width;
		height: 5px;
		display: flex;
		font-size: @font-size-kbd;
		padding-left: (@thumb-width / 2);
		margin-top: 18px;

		&_double {
			width: (@range-width * 2) - @thumb-width;
		}

		div {
			flex: 1;
			box-sizing: border-box;
			border-right: 1px solid @color-face-brd;
			position: relative;

			&:first-child {
				border-left: 1px solid @color-face-brd;
			}

			&[data-caption]:not(:last-child)::before {
				content: attr(data-caption);
				color: @color-dark-face-txt-kbd;
				position: absolute;
				left: -10px;
				top: -18px;
				width: 20px;
				height: 18px;
				text-align: center;
			}

			&[data-caption]:last-child::after {
				content: attr(data-caption);
				color: @color-dark-face-txt-kbd;
				position: absolute;
				right: -10px;
				top: -18px;
				width: 20px;
				height: 18px;
				text-align: center;
			}
		}
	}

	&__range {
		flex: 1;

		input {
			width: @range-width;
		}

		span {
			margin-left: @indent-small;
			color: @color-main-txt;
		}

		&_double {
			input {
				width: @range-width * 2;
			}
		}
	}

	&__range-input {
		display: flex;
		align-items: center;
	}

	&__input {
		display: block;

		&_big {
			width: 70%;

			.form_width-50 & {
				width: 50%;
			}
		}

		input {
			background-color: @color-main-bg;
			border: 1px solid @color-dark-face-brd;
			color: @color-main-txt;
			padding: @indent-large;
			box-sizing: border-box;
			border-radius: @border-radius;
			width: 100%;

			&[readonly] {
				background-color: transparent;
			}

			.form__group_error & {
				border: 1px solid @color-danger-bg;
			}
			.form__group_indicate & {
				border: 1px solid @color-indicator-brd;
			}
		}
	}

	&__textarea {
		display: block;
		flex: 1;

		textarea {
			width: 100%;
			height: 110px;
			resize: none;
			background-color: @color-main-bg;
			border: 1px solid @color-dark-face-brd;
			color: @color-main-txt;
			padding: @indent-large;
			border-radius: @border-radius;
			box-sizing: border-box;

			&[readonly] {
				background-color: transparent;
			}

			.form__group_error & {
				border: 1px solid @color-danger-bg;
			}
			.form__group_indicate & {
				border: 1px solid @color-indicator-brd;
			}
		}

		&_big {
			textarea {
				height: 300px;
			}
		}
	}

	&__select {
		select {
			background-color: @color-main-bg;
			border: 1px solid @color-dark-face-brd;
			color: @color-main-txt;
			padding: @indent-large;
			box-sizing: border-box;
			border-radius: @border-radius;
			width: 100%;

			&[readonly] {
				background-color: transparent;
			}

			.form__group_error & {
				border: 1px solid @color-danger-bg;
			}
			.form__group_indicate & {
				border: 1px solid @color-indicator-brd;
			}
		}
	}

	&__image {
		position: relative;
		background-color: @color-face2-bg;
		line-height: 0;

		span {
			display: none;
			position: absolute;
			width: 100%;
			height: 100%;
			line-height: @line-height-default;
		}

		img {
			max-width: 100%;
			max-height: 300px;
			min-width: 200px;
			min-height: 100px;
		}

		&_loading {
			img {
				visibility: hidden;
			}

			span {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
