@import "config";

a {
	text-decoration: none;
	color: inherit;

	&:visited {
		color: inherit;
	}

	&:active {
		color: inherit;
	}
}
