@import "/src/ui/style/config";

.scenes-matrix {
	position: relative;
	background-color: @color-main-bg;
	width: 100%;
	height: 100%;
	overflow: hidden;
	cursor: grab;

	&__row {
		display: flex;
		width: calc(var(--width) * var(--columns));
	}

	&__cell {
		width: calc(var(--width) - (var(--indent) * 2));
		height: calc(var(--height) - (var(--indent) * 2));
		overflow: hidden;

		video {
			width: var(--width);
		}
	}

	& > canvas {
		position: relative;
		z-index: 1;
	}
}

.video-scene {
	background-image: var(--bg-image);
	background-size: var(--width) var(--height);

	&_error {
		background-image: var(--bg-image);
		background-size: 20% 20%, var(--width) var(--height);
	}
}

.slots-matrix {
	position: absolute;
	z-index: 0;
	background-color: @color-main-bg;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	visibility: hidden;

	&__row {
		display: flex;
		width: calc(var(--width) * var(--columns));
	}

	&__cell {
		width: var(--width);
		height: var(--height);
		overflow: hidden;
		box-sizing: border-box;
		border: 1px solid #fff;
		color: #fff;
		padding: 10px;
		white-space: pre;
		background-size: calc(var(--width) * 2) calc(var(--height) * 2);
		background-position: calc((var(--col) * var(--width)) * -1) calc((var(--row) * var(--height)) * -1);

		&:before {
			content: ~"attr(data-slot-id)";
			display: block;
		}

		&:after {
			content: ~"attr(data-slot-size)";
			display: block;
		}
	}
}
