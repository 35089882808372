@import "config";

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font: @font;
	background-color: @color-dark-face-bg;
}

main {
	height: 100%;
}
