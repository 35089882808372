@import "/src/ui/style/config";

.popup-menu {
	position: fixed;
	background-color: @color-face-bg-lighter;
	border: 1px solid @color-face-brd;
	border-radius: 6px;
	color: @color-face-txt;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
	left: var(--x, 0);
	top: var(--y, 0);
	z-index: @z-index-popup;
	overflow: hidden;

	&__button {
		display: flex;
		height: @size-base-height;
		background-color: transparent;
		cursor: pointer;
		padding: 0 @indent-large;
		position: relative;
		text-decoration: none;
		align-items: center;

		svg {
			font-size: @font-size-big;
			vertical-align: middle;
		}

		span {
			margin-left: @indent-small;
		}

		&:hover {
			background-color: @color-dark-face-bg-lighter;
			span {
				color: @color-dark-face-txt;
			}

			svg {
				color: @color-dark-face-txt;
			}
		}

		&:active {
			background-color: @color-main-bg;
			span {
				color: @color-dark-face-txt;
			}

			svg {
				color: @color-dark-face-txt;
			}
		}

	}
}
