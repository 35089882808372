@import "config";

.button-mixin(@height) {
	display: inline-flex;
	height: @height;
	background-color: transparent;
	border: none;
	outline: none;
	appearance: none;
	cursor: pointer;
	color: @color-dark-face-txt;
	text-align: center;
	vertical-align: middle;
	font-weight: normal;
	padding: 0 @indent-large;
	position: relative;
	text-decoration: none;
	align-items: center;
	transition: background-color 0.2s linear;

	&[hidden] {
		display: none;
	}

	svg {
		font-size: @font-size-big;
		vertical-align: middle;
	}

	span {
		color: @color-dark-face-txt;
		font-size: @font-size-default;
		vertical-align: middle;
		margin-left: @indent-small;
		white-space: nowrap;
	}

	kbd {
		font-size: @font-size-kbd;
		color: @color-dark-face-txt-kbd;
		margin-left: @indent-small;
		margin-top: @indent-large;
	}

	&:hover {
		background-color: @color-dark-face-bg-lighter;

		span {
			color: @color-dark-face-txt;
		}

		kbd {
			color: @color-dark-face-txt;
		}
	}

	&:active {
		background-color: @color-main-bg;
		span {
			color: @color-dark-face-txt;
		}

		kbd {
			color: @color-dark-face-txt;
		}
	}

	:root &:disabled,
	:root &_disabled {
		pointer-events: none;
		i, svg {
			color: @color-dark-face-txt-disabled;
		}

		span {
			color: @color-dark-face-txt-disabled;
		}
	}

	&_border {
		border: 1px solid @color-dark-face-brd;
	}

	&_border-light {
		border: 1px solid @color-face-brd;
	}

	&_success {
		background-color: @color-success-bg;
		color: @color-success-txt;
	}

	.button__switch {
		margin-right: @indent-small;
		width: 22px;
		height: 22px;

		use[href="/images/icons.svg#icon-svg-turn-off"] {
			display: inline;
			color: @color-dark-face-txt-muted;
		}

		use[href="/images/icons.svg#icon-svg-turn-on"] {
			display: none;
			color: @color-dark-face-txt;
		}
	}

	&_checked {
		background-color: @color-main-bg;

		&:hover {
			background-color: @color-main-bg;
		}

		svg.button__switch {
			use[href="/images/icons.svg#icon-svg-turn-off"] {
				display: none;
			}

			use[href="/images/icons.svg#icon-svg-turn-on"] {
				display: inline;
			}
		}
	}

	.button__marker {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: @color-success-bg;

		&_alert {
			background-color: @color-danger-bg;
			box-shadow: 0 0 6px @color-danger-bg-lighter;
		}
	}
}
