@import "src/ui/style/config";

.change-area {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: @z-index-crop-area;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s linear;
	pointer-events: none;

	&_show {
		visibility: visible;
		opacity: 1;
	}

	&__head {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: @color-main-txt;
		bottom: calc(100vh - var(--y1, 0) + @indent-large);
		left: calc(var(--x1, 0) - 80px);
		width: calc(var(--x2, 0) - var(--x1, 0) + 160px);
		z-index: 2;

		.change-area_animated & {
			transition: left 200ms, bottom 200ms;
		}
	}

	&__top-line {
		display: flex;
		align-items: center;
	}

	&__title {
		color: @color-main-txt;
		padding: @indent-small;
		border-radius: 6px;
		max-width: 280px;
		text-align: center;
		text-shadow: 1px 1px 2px @color-dark-transparent-bg2;
		z-index: 2;
		text-wrap: pretty;
	}

	&__title a {
		color: @color-warning-bg-lighter;
		pointer-events: visible;
		cursor: pointer;
		text-decoration: underline;
	}

	&__title a:hover {
		color: @color-link-hover;
	}

	&__button {
		padding: 5px;
		pointer-events: visible;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		background-color: @color-light-transparent-bg;
		border-radius: @border-radius;
		box-shadow: 0 0 3px @color-dark-transparent-bg;
		transition: background-color 0.2s;

		&[href="#"], &[hidden] {
			display: none;
		}

		span {
			color: #000;
			font-size: 11px;
			margin-left: 3px;
		}

		&:hover {
			background-color: #fff;
		}
	}

	&__interactive {
		display: flex;
		justify-content: center;
		margin-top: 3px;
		z-index: 2;

		&[hidden] {
			display: none;
		}
	}

	&__interactive-button {
		display: inline-flex;
		padding: @indent-small @indent-large;
		background-color: @color-dark-color2-bg;
		border: 1px solid @color-dark-color2-brd;
		color: @color-dark-color2-txt;
		pointer-events: visible;
		cursor: pointer;
		border-radius: @border-radius;
		z-index: 2;

		&:hover {
			background-color: @color-dark-color2-bg-lighter;
		}
	}

	&__shadow {
		position: absolute;
		top: var(--y1, 0);
		left: var(--x1, 0);
		width: calc(var(--x2, 0) - var(--x1, 0));
		height: calc(var(--y2, 0) - var(--y1, 0));
		box-shadow: 0 0 0 500vmax @color-dark-transparent-bg;
		z-index: 1;
		border-radius: @border-radius;

		.change-area_animated & {
			transition: left 200ms, top 200ms, width 200ms, height 200ms;
		}
	}
}
