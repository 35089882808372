@import "/src/ui/style/config";

.spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0);
	z-index: @z-index-cover;
	transition: background-color 0.6s ease-out;
	cursor: wait;
	visibility: hidden;

	&_show {
		visibility: visible;
		background-color: rgba(0, 0, 0, 0.4);
	}
}