@import "/src/ui/style/config";

@changes-bar-width: 300px;

.changes-bar {
	position: fixed;
	left: -@changes-bar-width;
	top: calc(@size-header-height - 1px);
	height: calc(100% - @size-header-height);
	width: @changes-bar-width;
	max-width: 100vw;
	z-index: @z-index-header;
	box-sizing: border-box;
	transition: left 0.2s ease-out;

	@media screen and (max-width: 600px) {
		width: 100vw;
		left: -100vw;
		transition: none;
	}

	&_show {
		left: 0;
	}

	&__list {
		height: calc(100% - (@size-base-height + 1px) - (@size-header-height + 1px));
		overflow-y: auto;
	}
}
