@import "src/ui/style/config";

@logo-icon-size: 22px;

.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: @size-header-height;
	z-index: @z-index-header;
	font-size: @font-size-default;
	cursor: default;

	&_hide {
		.header__line {
			margin-top: -@size-header-height;
		}
	}

	&:hover {
		.header__line {
			margin-top: 0;
		}
	}

	&__line {
		position: relative;
		height: calc(@size-header-height - 1px);
		transition: margin-top 0.4s ease-in-out;
	}
}
