@import "/src/ui/style/config";

.fatal-error {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: @z-index-fatal-error;
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(4px);

	&__icon.svg-icon {
		font-size: @font-size-h2;
	}

	&__body {
		flex: 1;
		padding: 40px;
		background-color: @color-danger-bg;
		color: @color-danger-txt;
		text-align: center;
		font-size: @font-size-big;

		a {
			color: @color-danger-txt;
			text-decoration: underline;

			&:hover {
				color: @color-danger-txt;
			}

			&:visited {
				color: @color-danger-txt;
			}
		}
	}

	&__buttons {
		button, .button {
			display: inline-block;
			min-width: 100px;
			text-align: center;
			margin-top: 10px;
			box-sizing: border-box;
			padding: 7px 30px;
			background-color: @color-danger-bg-lighter;
			border: 1px solid @color-danger-bg-lighter;
			color: @color-danger-txt;
			font-size: @font-size-big;
			outline: none;
			cursor: pointer;
			text-decoration: none;

			&:hover {
				border-color: @color-danger-txt;
			}

			&:active {
				background-color: @color-danger-bg-darker;
				border-color: @color-danger-bg-darker;
				color: @color-danger-txt;
			}
		}
	}
}
